import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import GLBackground from "../effects/GLBackground"

const GLContainer = styled(GLBackground)`
  position: fixed;
`

const StyledImage = styled(Img)`
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
  z-index: 200000;
  height: auto;
  pointer-events: none;
`

const BackgroundFixed = () => {
  const data = useStaticQuery(graphql`
    query {
      fixedBackground: file(
        relativePath: { eq: "graphic-overlay-top-left.png" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div style={{ position: "fixed" }}>
      <Fade>
        <StyledImage
          fluid={data.fixedBackground.childImageSharp.fluid}
          style={{
            position: "absolute",
          }}
          alt="Subtle plastic wrapper effect overlaying the top left edges of the website."
        />
        <GLContainer />
      </Fade>
    </div>
  )
}

export default BackgroundFixed
