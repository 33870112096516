import * as THREE from "three"
import React, { useEffect, useRef, useMemo } from "react"
import { Canvas, extend, useFrame, useThree } from "react-three-fiber"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { WaterPass } from "./Waterpass"
import useMousePosition from "../hooks/mousePosition"

// Makes these prototypes available as "native" jsx-string elements
extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  WaterPass,
  UnrealBloomPass,
})

const Swarm = ({ count, mouse }) => {
  const mesh = useRef()

  const dummy = useMemo(() => new THREE.Object3D(), [])
  // Generate some random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i += 1) {
      const t = Math.random() * 100
      const factor = 20 + Math.random() * 100
      const speed = 0.01 + Math.random() / 2000
      const xFactor = -50 + Math.random() * 100
      const yFactor = -50 + Math.random() * 100
      const zFactor = -50 + Math.random() * 100
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])

  // The innards of this hook will run every frame
  useFrame(() => {
    // Makes the light follow the mouse
    // light.current.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 0)
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      // There is no sense or reason to any of this, just messing around with trigonometric functions
      t = particle.t += speed / 10000
      const a = Math.cos(t) + Math.sin(t * 1) / 10
      const b = Math.sin(t) + Math.cos(t * 2) / 10
      const s = Math.cos(t)
      particle.mx += (mouse.x - particle.mx) * 0.001
      particle.my += (mouse.y * -1 - particle.my) * 0.001
      // Update the dummy object
      dummy.position.set(
        (particle.mx / 10) * a +
          xFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b +
          yFactor +
          Math.sin((t / 10) * factor) +
          (Math.cos(t * 2) * factor) / 10,
        Math.min(
          (particle.my / 10) * b +
            zFactor +
            Math.cos((t / 10) * factor) +
            (Math.sin(t * 3) * factor) / 10,
          10
        )
      )
      dummy.scale.set(s, s, s)
      dummy.rotation.set(s * 5, s * 5, s * 5)
      dummy.updateMatrix()
      // And apply the matrix to the instanced item
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })
  return (
    <>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <sphereBufferGeometry attach="geometry" args={[0.2, 16, 16]} />
        <meshStandardMaterial attach="material" color="#cccccc" />
      </instancedMesh>
    </>
  )
}

const Effect = () => {
  const composer = useRef()
  const { scene, gl, size, camera } = useThree()
  useEffect(() => void composer.current.setSize(size.width, size.height), [
    size,
  ])
  useFrame(() => composer.current.render(), 1)
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <waterPass attachArray="passes" factor={0.25} />
    </effectComposer>
  )
}

const GLBackground = () => {
  const position = useMousePosition()
  let mousePosition = { x: 0, y: 0 }
  if (typeof window !== "undefined") {
    mousePosition = {
      x: position.x - window.innerWidth / 2,
      y: position.y - window.innerHeight / 2,
    }
  }

  return (
    <Canvas
      className="glCanvas"
      camera={{ fov: 100, position: [0, 0, 40] }}
      color="#222533"
      transparent="true"
    >
      <pointLight distance={400} intensity={5} color="#222533" />
      <spotLight intensity={2} position={[0, 0, 170]} color="#111111" />
      <ambientLight color="#222533" />
      <Swarm mouse={mousePosition} count={100} />
      <Effect />
    </Canvas>
  )
}

export default GLBackground
