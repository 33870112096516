import React from "react"
import { Formik } from "formik"
import FloatingLabelInput from "react-floating-label-input"
import styled from "styled-components"
import media from "styled-media-query"
import PrimaryButton from "../button/primary-button"

const Input = styled(FloatingLabelInput)`
  color: #bababa;
  background-color: transparent;
  border-bottom-color: #535663;
`

const ContactDescription = styled.div`
  color: #9c9eab;
  font-size: 0.7rem;
  font-style: italic;
  line-height: 0.9rem;
  margin-top: 1rem;
  margin-bottom: 2.2rem;
  padding-left: 160px;
  text-align: right;
  pointer-events: none;
`

const TopFormContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

const Spacer = styled.div`
  width: 80px;
  ${media.lessThan("medium")`
    height: 40px;
  `}
`

const Success = styled.div`
  font-family: "Roobert SemiBold";
  font-size: 1rem;
`

const Error = styled.div`
  font-family: "Nitti Bold";
  color: #7c365f;
  margin-top: 20px;
  margin-left: 20px;
`

const encode = data => {
  const objectData = Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")
  console.log(objectData)
  return objectData
}

const Basic = ({ darkMode, backgroundImage }) => (
  <div>
    <Formik
      initialValues={{ name: "", email: "", note: "" }}
      validate={values => {
        const errors = {}
        if (!values.name.trim()) {
          errors.name = "Please enter your name."
        }
        if (!values.note.trim()) {
          errors.note = "Please briefly describe your project."
        }
        if (!values.email) {
          errors.email = "Please enter your email."
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address."
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...values }),
        })
          .then(() => {
            setSubmitting(false)
            resetForm()
            setStatus({ success: "Thanks, we'll be in touch soon." })
          })
          .catch(error => setSubmitting(false))
      }}
    >
      {({
        values,
        errors,
        touched,
        status,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <TopFormContainer>
            <Input
              name="name"
              label="Name *"
              required
              onChange={handleChange}
              value={values.name}
            />
            <Spacer />
            <Input
              name="email"
              required
              label="Email *"
              onChange={handleChange}
              type="email"
              value={values.email}
            />
          </TopFormContainer>
          <br />
          <br />
          <Input
            name="note"
            component="textarea"
            required
            label="How can we help? *"
            onChange={handleChange}
            value={values.note}
          />
          <input type="hidden" name="form-name" value="contact" />
          <ContactDescription />
          <Success>{status ? status.success : ""}</Success>
          {status === undefined && (
            <PrimaryButton
              type="submit"
              disabled={isSubmitting || Object.keys(errors).length > 0}
              darkMode={darkMode}
              backgroundImage={backgroundImage}
              link=""
            >
              Send
            </PrimaryButton>
          )}
          <Error>
            {Object.keys(errors).map(key =>
              touched[key] && errors[key] && status !== undefined ? (
                <div key={key}>{errors[key]}</div>
              ) : null
            )}
          </Error>
        </form>
      )}
    </Formik>
  </div>
)

export default Basic
