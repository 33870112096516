import { useEffect, useState } from "react"

// https://codedaily.io/tutorials/60/Create-a-useMousePosition-Hook-with-useEffect-and-useState-in-React

const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0, pageY: 0 })
  useEffect(() => {
    const setFromEvent = e => {
      setPosition({ x: e.clientX, y: e.clientY, pageY: e.pageY })
    }
    window.addEventListener("mousemove", setFromEvent)
    return () => {
      window.removeEventListener("mousemove", setFromEvent)
    }
  }, [])
  return position
}

export default useMousePosition
