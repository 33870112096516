import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import FHLockup from "../../svgs/futurehaus_logo_mark_white.svg"

const MainNavigation = styled.div`
  display: flex;
  align-items: stretch;
  width: 80vw;
  max-width: 1124px;
  padding: 100px 0px 1.45rem 0px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;

  ${media.lessThan("medium")`
    padding: 46px 0px 34px 0px;
    font-size: 36px;
    line-height: 2rem;
  `}
`

const NavHome = styled(FHLockup)`
  width: 210px;

  ${media.lessThan("medium")`
    width: 160px;
    padding-top: 6px;
  `}
`

const Spacer = styled.div`
  flex-grow: 1;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;

  ${media.lessThan("medium")`
    align-content: flex-start;
  `}
`

const ALink = styled(AniLink)`
  padding-left: 60px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  line-height: 1.8rem !important;
  font-family: "Nitti Bold";
  text-decoration: none;
  color: #cacaca;

  ${media.lessThan("medium")`
    margin-right: -15px;
    font-size: 10px;
    line-height: .2rem !important;
    min-height: 0px;
  `}
`

const Header = ({ activeSection }) => {
  return (
    <MainNavigation id="who">
      <Fade>
        <AniLink to="/" cover direction="left" bg="#222533">
          <NavHome />
        </AniLink>
      </Fade>
      <Spacer />
      <MenuContainer>
        <Fade>
          <ALink
            to="/"
            cover
            direction="left"
            bg="#222533"
            className={activeSection === "home" ? "activeLink" : "menuLink"}
          >
            Haus
          </ALink>
          <ALink
            to="/#work"
            cover
            direction="left"
            bg="#222533"
            className={activeSection === "work" ? "activeLink" : "menuLink"}
          >
            Work
          </ALink>
          <ALink
            to="/about"
            cover
            direction="left"
            bg="#222533"
            className={activeSection === "about" ? "activeLink" : "menuLink"}
          >
            About
          </ALink>
        </Fade>
      </MenuContainer>
    </MainNavigation>
  )
}

export default Header
