import React from "react"
import styled from "styled-components"
import SmoothScroll from "smooth-scroll"

import Header from "./header"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./layout.css"
import Footer from "./footer"

try {
  SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    clip: true,
    updateURL: true, // Update the URL on scroll
    popstate: true, // Animate scrolling with the forward/backward browser buttons (requires updateURL to be true)
  })
} catch (e) {
  // Empty on purpose
}

const StyledHeader = styled(Header)`
  position: fixed;
`

const LayoutWrap = styled.div`
  position: relative;
`

const LayoutContainer = styled.div`
  margin: 0 auto;
  width: 80vw;
  max-width: 1124px;
  padding-top: 0px;
`

const Layout = ({ children, activeSection }) => {
  return (
    <LayoutWrap>
      <StyledHeader activeSection={activeSection} />
      <LayoutContainer>
        <main>{children}</main>
        <Footer />
      </LayoutContainer>
    </LayoutWrap>
  )
}

export default Layout
