import React from "react"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import media from "styled-media-query"

import FHLogo from "../../svgs/futurehaus_logo_full_white.svg"

const SectionHorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 85px;
  margin-bottom: 80px;
  background-color: #535663;
`

// Footer

const FooterLink = styled.a`
  display: flex;
  margin-left: 0.75rem;
`

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 60px;
  margin-right: 14%;
  width: 100%;

  ${media.lessThan("medium")`
    flex-direction: column-reverse;
    align-items: center;
    height: 240px;
  `}
`

const Logo = styled(FHLogo)`
  height: 32px;
  width: 32px;
`

const FooterRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 14%;

  ${media.lessThan("medium")`
    margin-right: 0px;
  `}
`

const FooterSpacer = styled.div`
  width: 111px;

  ${media.lessThan("medium")`
    width: 98px;
  `}
`

const FooterRightSub = styled.div`
  display: flex;
`

const SocialContainer = styled.div`
  display: flex;
`

const SocialLink = styled.a`
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  ${media.lessThan("medium")`
    padding-right: 0px;
  `}
`

const Spacer = styled.div`
  flex-grow: 1;
`

const FooterItem = styled.div`
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  pointer-events: none;
`

const Footer = () => {
  return (
    <>
      <SectionHorizontalRule />
      <Fade>
        <FooterContainer>
          <Logo />
          <Spacer />
          <FooterRightContainer>
            <SocialContainer>
              <FooterItem>Follow</FooterItem>
              <FooterItem>-</FooterItem>
              <SocialLink
                href="https://www.facebook.com/Futurehaus"
                rel="noopener noreferrer"
                target="_blank"
              >
                Fb
              </SocialLink>
              <SocialLink
                href="https://www.linkedin.com/company/futurehaus/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Li
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/futurehaus/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Ig
              </SocialLink>
              <SocialLink
                href="https://medium.com/futurehaus"
                rel="noopener noreferrer"
                target="_blank"
              >
                Me
              </SocialLink>
              <SocialLink
                href="https://dribbble.com/Futurehaus"
                rel="noopener noreferrer"
                target="_blank"
              >
                Dr
              </SocialLink>
            </SocialContainer>
            <FooterRightSub>
              <FooterItem>Info</FooterItem>
              <FooterItem>&nbsp;&nbsp;-</FooterItem>
              <FooterLink href="https://maps.app.goo.gl/1Ep1QyFov1ijo55t7">
                <div>
                  125 S Clark St Suite 1700
                  <br />
                  <div style={{ marginTop: "-8px" }}>Chicago, IL 60603</div>
                </div>
              </FooterLink>
            </FooterRightSub>
            <FooterRightSub>
              <FooterSpacer />
              <FooterLink href="tel:312-985-6537">312.985.6537</FooterLink>
            </FooterRightSub>
          </FooterRightContainer>
        </FooterContainer>
      </Fade>
    </>
  )
}

export default Footer
