import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import linkBackgroundImage from "../../images/graphic-link-bg-bright.png"

const PrimaryButton = ({
  darkMode,
  backgroundImage,
  link,
  children,
  blank,
  type,
  topSpacing,
}) => {
  const ALink = styled(AniLink)`
    padding: 2px 22px 0px 18px;
    height: 31px;
    font-size: 0.75rem;
    color: ${darkMode ? "#222533" : "#fff"};
    vertical-align: middle;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${backgroundImage === undefined
      ? linkBackgroundImage
      : backgroundImage});
    text-align: left;
    min-width: 100px;
    z-index: 2000;
    margin-top: ${topSpacing ? 40 : 0}px;

    cursor: pointer;

    ${media.lessThan("medium")`
                font-size: 12px;
                min-width: 80px;
        `}
    &:hover {
      text-decoration: none;
    }
  `

  const ButtonStyle = styled.button`
    padding: 0px 22px 0px 18px;
    height: 31px;
    font-size: 0.75rem;
    white-space: nowrap;
    color: ${darkMode ? "#222533" : "#fff"};
    vertical-align: middle;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${backgroundImage === undefined
      ? linkBackgroundImage
      : backgroundImage});
    text-align: left;
    min-width: 100px;
    z-index: 2000;
    margin-top: ${topSpacing ? 40 : 0}px;

    cursor: pointer;

    ${media.lessThan("medium")`
                font-size: 12px;
                min-width: 80px;
        `}
  `

  let button = (
    <ButtonStyle
      onClick={() => {
        if (type === "submit") {
          // TODO: Add goal for Contact Us (Lead)
        }

        if (link.includes("Tell us a little about what you do.")) {
          // TODO: Add goal for worker prospect
        }

        if (link.length > 0) {
          if (blank) {
            window.open(link === undefined ? "/" : link, "_blank")
          } else {
            window.location.href = link === undefined ? "/" : link
          }
        }
      }}
      className="primaryButton"
    >
      {children}
    </ButtonStyle>
  )

  if (link.includes("#")) {
    button = (
      <ALink
        to={link}
        cover
        direction="left"
        bg="#222533"
        className="primaryButton"
      >
        {children}
      </ALink>
    )
  }

  return button
}

export default PrimaryButton
