import React from "react"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import media from "styled-media-query"
import Basic from "./forms/basic"

// Contact styles

const ContactSection = styled.div`
  margin-top: 200px;

  ${media.lessThan("medium")`
    margin-top: 20px;
  `}
`

const ContactSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const ContactFormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 84px;

  ${media.lessThan("medium")`
    margin-right: 0px;
  `}
`

const ContactSectionForm = styled.div`
  margin-top: 40px;
  width: 500px;
`

const Contact = ({ children, small, darkMode, backgroundImage }) => {
  const ContactHeader = styled.div`
    font-family: "Roobert SemiBold";
    line-height: ${small ? 1.0 : 1.1};
    margin-top: -40px;
    margin-bottom: 1.45rem;
    font-size: ${small ? 60 : 100}px;
    margin-left: 50px;
    text-align: right;
    color: #b8b8b8;
    pointer-events: none;

    ${media.lessThan("medium")`
          font-size: 35px;
          margin-top: 40px;
        `}
  `

  const DefaultHeader = (
    <>
      Have an idea?
      <br />
      Reach out
    </>
  )

  return (
    <ContactSection id="contact">
      <ContactSectionHeader>
        <Fade bottom duration={1250} distance="25px">
          <ContactHeader>
            {children === undefined ? DefaultHeader : children}
          </ContactHeader>
        </Fade>
      </ContactSectionHeader>
      <ContactFormContainer>
        <ContactSectionForm>
          <Fade right distance="20px">
            <Basic darkMode={darkMode} backgroundImage={backgroundImage} />
          </Fade>
          <Fade />
        </ContactSectionForm>
      </ContactFormContainer>
    </ContactSection>
  )
}

export default Contact
