import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"

import { isMobile, isIOS } from "react-device-detect"

const ControlSpacer = styled.div`
  width: 10px;
`

class NextPrev extends Component {
  render() {
    const SliderControls = styled.div`
      display: flex;
      flex-direction: row;
      position: absolute;
      z-index: 200;
      margin-left: ${this.props.mobileScreensOnly ? "55vw" : "820px"};
      margin-top: ${this.props.mobileScreensOnly ? -625 : -620}px;

/* Firefox */
@-moz-document url-prefix() {
    & {
      margin-left: 950px;
          margin-top: -650px;
    }
  }

  /* Microsoft IE10 and above */
      @media all and (-ms-high-contrast: none) {
        & {
          margin-left: 950px;
          margin-top: -650px;
        }
      }

      /* Microsoft Edge */
      @supports (-ms-ime-align: auto) {
        & {
          margin-left: 950px;
          margin-top: -650px;
        }
      }

      ${media.lessThan("1000px")`
          margin-top: ${this.props.mobileScreensOnly ? 125 : -220}px;
        `}

      ${media.lessThan("medium")`
          margin-right: 30px;
          font-size: 18px;
          line-height: 1rem;
          margin-top: ${this.props.mobileScreensOnly ? 25 : 120}px;
          right: 10px;
          flex-direction: column;
          justify-content: space-between;
        `}
    `

    const ButtonArrow = styled.div`
      font-size: 31px;
      font-family: "Roobert SemiBold";
      line-height: 1.1;
      color: #b8b8b8;
      margin: 8px auto auto auto;

      /* Firefox */
@-moz-document url-prefix() {
    & {
      margin-top: 6px;
    }
  }
  /* Microsoft IE10 and above */
      @media all and (-ms-high-contrast: none) {
    & {
      margin-top: 4px;
    }
  }
/* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: 4px;
    }
  }

      &:hover {
        color: ${this.props.darkMode ? "#222533" : "#b8b8b8"};
      }

      ${media.lessThan("medium")`
          font-size: 18px;
          margin: auto auto 4px auto;
          margin-left: ${isIOS ? "-4": "8"}px;
          color: ${this.props.darkMode ? "#222533" : "#b8b8b8"};
      `}
    `
    const SliderButton = styled.button`
      display: flex;
      width: 55px;
      height: 55px;
      border-width: 0px;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: ${this.props.darkMode ? "#222533" : "#b8b8b8"};
        background-color: ${this.props.color ?? "#7c365f"};
      }

      ${media.lessThan("medium")`
                width: 40px;
                height: 40px;
                margin-bottom: 10px;
                background-color: ${this.props.color ?? "#7c365f"};
            `}
    `
    return (
      <SliderControls>
        <SliderButton onClick={this.props.previous}>
          <ButtonArrow className="buttonArrow">←</ButtonArrow>
        </SliderButton>
        <ControlSpacer />
        <SliderButton onClick={this.props.next}>
          <ButtonArrow className="buttonArrow">→</ButtonArrow>
        </SliderButton>
      </SliderControls>
    )
  }
}

export default NextPrev
